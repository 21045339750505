/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet-async'
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import GravityForm from 'components/GravityForm'
import ParseContent from 'components/shared/ParseContent'
import parse from 'html-react-parser'
import styled from 'styled-components'
import { removeHTML } from '../components/SEO'

const BlogContent = styled.div`
  h3 {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-top: 40px;
  }
`

const Story = styled.div`
  p,
  li {
    font-size: ${(props) => props.theme.font.size.sm};
    color: ${(props) => props.theme.color.text.dark};
    line-height: 30px;
  }
`

const ContactLink = styled.div``

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressWpVacatures(wordpress_id: { eq: $wordpress_id }) {
      ...SingleVacatureFrag
    }
  }
`

const FormDiv = styled.div`
  background: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  border-radius: 20px;
`

const Person = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`

const PageTemplate = ({ data }) => {
  const structuredData = {
    title:
      data.page.yoast_title ||
      data.page.yoast_meta.find(({ property }) => property === 'og:title')
        ?.content ||
      data.page.title,
    description: data.page.yoast_meta.find(({ name }) => name === 'description')
      .content,
  }

  return (
    <Layout>
      <SEO
        yoast_title={data.page.yoast_title}
        yoast={data.page.yoast_meta}
        path={data.page.path}
        image={{
          url:
            data.page.acf.content.image.localFile &&
            data.page.acf.content.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context" : "https://schema.org/",
            "@type" : "JobPosting",
            "title" : "${removeHTML(structuredData.title)}",
            "description" : "<p>${structuredData.description}</p>",
            "identifier": {
              "@type": "PropertyValue",
              "name": "ClearMind",
              "value": "${data.page.wordpress_id}"
            },
            "datePosted" : "${data.page.date}",
            "employmentType" : "CONTRACTOR",
            "hiringOrganization" : {
              "@type" : "Organization",
              "name" : "ClearMind",
              "sameAs" : "https://clearmind.nu/",
              "logo" : "https://admin.clearmind.nu/wp-content/uploads/2023/06/logo-cm.png"
            },
            "jobLocation": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Hegedyk 58",
                "addressLocality": "Luxwoude",
                "postalCode": "8405 GV",
                "addressCountry": "NL"
              }
            }
          }`}
        </script>
      </Helmet>

      <Hero
        className="mb-lg-3"
        article
        backUrl="/vacatures/"
        title={parse(data.page.title)}
        content={data.page.acf.content}
      />

      <div className="container pb-5">
        <div className="row justify-content-center">
          <BlogContent className="col-lg-9 pr-5">
            <Story className="py-5">{parse(data.page.acf.content.story)}</Story>
          </BlogContent>
        </div>
        <div className="row m-0">
          <FormDiv className="col-lg-10 mx-auto p-3 p-lg-5 d-flex flex-wrap">
            <div className="col-lg-6 px-lg-0 mb-4 mb-lg-0">
              <GravityForm id={13} brochure />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-start align-items-center">
              <Person
                fluid={data.page.acf.cta.image.localFile.childImageSharp.fluid}
              />
              <ParseContent content={data.page.acf.cta.description} />
            </div>
          </FormDiv>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
